import { Skeleton } from 'antd';
import { FC, PropsWithChildren, useEffect, useState } from 'react';

export const InitWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return <Skeleton loading={isLoading}>{children}</Skeleton>;
};
