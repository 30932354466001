import { atom } from 'jotai';
import Keycloak from 'keycloak-js';
import { KeycloakTokenInfo } from 'shared/@types/auth';

const keycloak = Keycloak({
  clientId: window.AUTH_CLIENT_ID,
  realm: window.AUTH_REALM,
  url: window.AUTH_URL,
});

const keycloakInstance = atom(keycloak);

const keycloakTokenInfo = atom<KeycloakTokenInfo>(get => {
  const atomInstance = get(keycloakInstance);
  return (atomInstance.idTokenParsed as KeycloakTokenInfo) ?? {};
});

export const auth = {
  keycloakInstance,
};
